import React, { useState, useEffect, useRef } from "react";
import Styles from "./index.module.css";
import Layout from "Components/Layout";
import Mainloader from "Components/loader/loader";
import { useForm, Controller } from "react-hook-form";
import ReactLoading from "react-loading";
import { Services } from "Services";
import { warningSwal, SuccessSwal } from "Util/Toast";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Country } from "country-state-city";
import { PhoneNumberValidate } from "Components/validators";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import TimePicker from "rc-time-picker";
import { AiOutlineClose } from "react-icons/ai";

function AddDemo() {
    const Navigate = useNavigate();
    const dropDownRef = useRef();

    let Countries = Country.getAllCountries();
    const [loading, setLoading] = useState(true);
    const [options, setOptions] = useState([]);
    const [searchApi, setSearchAPI] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const [courses, setCourses] = useState([]);
    const [mainload, setMainLoad] = useState(false);
    const [phoneNumber, setphoneNumber] = useState("");
    const [parentDetails, setParentDetails] = useState({});
    const [tutors, setTutors] = useState([]);
    const [selectedTutor, setSelectedTutor] = useState(null);
    const [tutorSearchString, setTutorSearchString] = useState("");
    const [searchTerm, setSearchTerm] = useState("");

    const [tutorSearchLoad, setTutorSearchLoad] = useState(false);
    const [selectedcountry, setSelectedCountry] = useState({
        PhoneCode: "91",
        Country: "IN",
        Name: "India",
    });
    const {
        register,
        handleSubmit,
        reset,
        watch,
        control,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const formData = watch();
    const FetchDropDowns = async () => {
        try {
            const [response1, response2] = await Promise.all([
                Services.getCourses("GET", null, token),
            ]);
            setLoading(false);

            setCourses(response1.Courses);
        } catch (error) {
            alert("something went wrong please try again");
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        FetchDropDowns();
    }, []);
    const handleTutorChange = (searchString) => {
        setTutorSearchString(searchString);
    };
    const onTutorSelect = (searchString) => {
        setSelectedTutor(searchString);
    };
    const handleOutsideClick = (event) => {
        if (
            dropDownRef.current &&
            !dropDownRef.current.contains(event.target)
        ) {
            // Clicked outside the dropdown, so close it
            setOptions([]); // Clear the options to close the dropdown
        }
    };
    const handleInputChange = (event) => {
        if (!formData.CourseID) {
            alert("please Select Course");
            return;
        }

        setSearchTerm(event.target.value);
    };
    const handleSearch = async () => {
        if (!formData.CourseID) {
            alert("please Select Course");
            return;
        }

        if (searchTerm?.length === 0) {
            alert("Enter valid Mobile number");
            return;
        }

        Services.getDemoRequetsSearch(
            "GET",
            null,
            token,
            formData.CourseID,
            searchTerm
        )
            .then((response) => {
                if (response?.EnrolledStudents?.length === 0) {
                    alert("NO Student Found");
                    return;
                }

                const data = response?.EnrolledStudents?.map((item) => {
                    return { id: item.StudentID, label: item.StudentName };
                });
                setSearchAPI(response?.EnrolledStudents);
                setOptions(data);
            })
            .catch((error) => {
                console.error("Error fetching options:", error);
            });
    };
    useEffect(() => {
        const debouncedSearch = setTimeout(async () => {
            if (
                typeof tutorSearchString == "string" &&
                tutorSearchString?.length > 0
            ) {
                setTutorSearchLoad(true);

                try {
                    const data = await Services.searchActiveTutor(
                        "POST",
                        JSON.stringify({ SearchString: tutorSearchString }),
                        token,
                        selectedTutor
                    );
                    const formattedOptions = data.Tutors?.filter(
                        (item) => item.TutorStatus == 1
                    )?.map((tutor) => ({
                        label: `${tutor.FirstName} ${tutor.LastName}`,
                        value: tutor.TutorID,
                        Email: tutor.EmailID,
                    }));
                    setTutors(formattedOptions);
                } catch (error) {
                    console.error("Error fetching options:", error);
                } finally {
                    setTutorSearchLoad(false);
                }
            }
        }, 300);

        return () => clearTimeout(debouncedSearch);
    }, [tutorSearchString]);
    const onSubmit = (data) => {};
    const handleTimeChange = (type, event) => {
        if (type == "time1") {
            setValue("Time1", moment(event).format("HH:mm"));
            clearErrors("Time1");
        }
    };
    const buttonHandler = (e) => {
        let id = e.currentTarget.getAttribute("id");
        // eslint-disable-next-line
        if (
            selectedOptions.filter((item) => item.StudentID == id)?.length > 0
        ) {
            alert("student Already Added ");
            return;
        }
        setOptions((prev) => {
            // eslint-disable-next-line
            return prev.filter((item) => item.id != id);
        });
        setSearchTerm("");
        // eslint-disable-next-line
        let selectedData = searchApi?.filter((item) => item.StudentID == id);

        if (formData.BatchSize === "One-to-One") {
            setSelectedOptions(selectedData);
        } else {
            setSelectedOptions((prev) => {
                return [...prev, ...selectedData];
            });
        }
    };
    const deleteHandler = (e) => {
        let studentid = e.currentTarget.getAttribute("studentid");
        // eslint-disable-next-line
        let newData = selectedOptions.filter(
            // eslint-disable-next-line
            (item) => item.StudentID != studentid
        );
        setSelectedOptions(newData);
    };
    return (
        <Layout Active={"Demo Classes"}>
            <div className={Styles["Container"]}>
                <div className={Styles["wrapper"]}>
                    <h3>Add Class</h3>
                    {mainload ? (
                        <Mainloader />
                    ) : (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className={Styles["form-control"]}>
                                <label htmlFor="Last Name">
                                    Course <span>*</span>
                                </label>
                                <select
                                    defaultValue={""}
                                    {...register("CourseID", {
                                        required: true,
                                    })}>
                                    <option value="" disabled>
                                        Select Course
                                    </option>
                                    {courses?.map((item) => (
                                        <option value={item.CourseID}>
                                            {item.CourseName}
                                        </option>
                                    ))}
                                </select>
                                {errors.CourseID && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            <div className={Styles["form-control"]}>
                                <label htmlFor="ContactNumber">
                                    Batch Size <span>*</span>
                                </label>

                                <select
                                    defaultValue={""}
                                    {...register("BatchType", {
                                        required: true,
                                    })}>
                                    <option value="" disabled>
                                        Select Batch Size
                                    </option>

                                    <option value="One-to-One">
                                        One-to-One
                                    </option>
                                    <option value={"Group"}>Group</option>
                                </select>
                                {errors.BatchType && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            <div className={Styles["form-control"]}>
                                <label htmlFor="">
                                    Demo Date<span>*</span>
                                </label>
                                <input
                                    className={
                                        errors?.DOB ? Styles["error"] : ""
                                    }
                                    type={"date"}
                                    {...register("DOB", {
                                        required: true,
                                    })}
                                />
                                {errors.DOB && (
                                    <span className={Styles["error-msg"]}>
                                        This field is required
                                    </span>
                                )}
                            </div>
                            <div className={Styles["form-control"]}>
                                <label htmlFor="">
                                    Demo Time<span>*</span>
                                </label>
                                <Controller
                                    name="Time1"
                                    control={control}
                                    defaultValue={""}
                                    rules={{
                                        required: "This field is required",
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <TimePicker
                                                className={
                                                    errors?.Time1
                                                        ? Styles["error"]
                                                        : ""
                                                }
                                                defaultOpenValue={moment()}
                                                minuteStep={5}
                                                allowEmpty={false}
                                                showSecond={false}
                                                placeholder="preferred  Time "
                                                // placement={"topRight"}
                                                onChange={(date) => {
                                                    field.onChange(date);
                                                    handleTimeChange(
                                                        "time1",
                                                        date
                                                    );
                                                }}
                                                format="h:mm a"
                                                use12Hours
                                            />
                                            {errors.Time1 && (
                                                <p
                                                    className={
                                                        Styles["error-msg"]
                                                    }>
                                                    this field s required
                                                </p>
                                            )}
                                        </>
                                    )}
                                />
                            </div>
                            <div className={Styles["form-control"]}>
                                <label htmlFor="">
                                    Tutor<span>*</span>
                                </label>
                                <Select
                                    options={tutors}
                                    maxMenuHeight={90}
                                    menuPosition="absolute"
                                    menuShouldBlockScroll
                                    onInputChange={handleTutorChange}
                                    value={selectedTutor}
                                    onChange={onTutorSelect}
                                    placeholder="Search Tutor"
                                    classNamePrefix="select_demorequest"
                                    isLoading={tutorSearchLoad}
                                    styles={{
                                        // Set the width to 100%
                                        control: (provided) => ({
                                            ...provided,
                                            width: "100%",
                                        }),
                                    }}
                                />
                            </div>
                            <div
                                className={`${Styles["form-control"]}`}
                                ref={dropDownRef}
                                style={{
                                    // gridRow: "3/-1",
                                    gridColumn: "1 / -1",
                                }}>
                                <label htmlFor="Student">
                                    {" "}
                                    Student <span className="important">*</span>
                                </label>
                                <div>
                                    <div className={Styles["students-select"]}>
                                        <input
                                            type="text"
                                            value={searchTerm}
                                            placeholder="Enter phone number (without country code)"
                                            onChange={handleInputChange}
                                        />
                                        <button
                                            className={Styles["numbersearch"]}
                                            onClick={handleSearch}
                                            type="button">
                                            Search
                                        </button>
                                        {options.length > 0 && (
                                            <div
                                                className={
                                                    Styles["students-options"]
                                                }>
                                                {options.length > 0 &&
                                                    options.map((option) => (
                                                        <div key={option.id}>
                                                            <button
                                                                id={option.id}
                                                                type="button"
                                                                onClick={
                                                                    buttonHandler
                                                                }>
                                                                {" "}
                                                                {option.label}
                                                            </button>
                                                        </div>
                                                    ))}
                                            </div>
                                        )}
                                    </div>
                                    {selectedOptions.length > 0 && (
                                        <div
                                            className={
                                                Styles["selected-studentdata"]
                                            }>
                                            {selectedOptions.map((item) => {
                                                return (
                                                    <div
                                                        key={`${item.StudentID}21`}>
                                                        <button
                                                            onClick={
                                                                deleteHandler
                                                            }
                                                            studentid={
                                                                item.StudentID
                                                            }
                                                            type="button">
                                                            <AiOutlineClose />
                                                        </button>
                                                        <p>
                                                            {item.StudentName}
                                                        </p>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default AddDemo;
