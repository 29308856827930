import React, { useState, useEffect } from "react";
import rolesClasses from "./roles.module.css";
import { Services } from "../../Services";
import { toast } from "react-toastify";
import { warningSwal, SuccessSwal } from "../../Util/Toast";
import MainLoad from "../../Components/loader/loader";
import { useNavigate } from "react-router-dom";
import Permissions from "./config";
import Layout from "../../Components/Layout";
import { AccessKeys } from "../../Routes/config";
function Warehouse() {
    const Navigate = useNavigate();
    let [loading, setLoading] = useState(true);
    const [adminroles, setadminroles] = useState([]);
    const [accessItems, setAccessItems] = useState({ AccessItems: [] });
    const [roleName, setRoleName] = useState("");
    const [loadingaccesitem, setloadingaccessitems] = useState(true);

    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const compare = (firstArray, secondArray) => {
        console.log(firstArray, secondArray);
        const resultArray = firstArray.concat(
            secondArray.filter((secondObj) => {
                // Check if the object from the second array is not already in the first array
                return !firstArray.some(
                    (firstObj) => firstObj.name === secondObj.name
                );
            })
        );
        return resultArray;
    };
    const getAdminroles = () => {
        Services.getAdminroles("GET", null, token)
            .then((res) => {
                console.log(res);
                setTimeout(() => {
                    setLoading(false);
                }, 300);
                if (res.Status === 1) {
                    setloadingaccessitems(false);
                    setadminroles(res.AdminRoles);
                    if (roleName.length > 0) {
                        let AccessData = res.AdminRoles.filter(
                            (item) => item.AdminRoleID == roleName
                        );
                        console.log(compare(AccessData, Permissions));
                        if (AccessData.length > 0) {
                            setAccessItems({
                                ...AccessData[0],
                                AccessItems: compare(
                                    AccessData[0].AccessItems,
                                    Permissions
                                ),
                            });
                        } else {
                            setAccessItems({
                                ...res.AdminRoles[0],
                                AccessItems: compare(
                                    res.AdminRoles[0].AccessItems,
                                    Permissions
                                ),
                            });
                        }
                    } else {
                        setAccessItems({
                            ...res.AdminRoles[0],
                            AccessItems: compare(
                                res.AdminRoles[0].AccessItems,
                                Permissions
                            ),
                        });
                    }
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        getAdminroles();
    }, []);

    const activeHandler = (e) => {
        let AdminRoleID = e.target.value;
        setRoleName(AdminRoleID);
        let [activeroleData] = adminroles.filter((item) => {
            setloadingaccessitems(true);
            return item.AdminRoleID == AdminRoleID;
        });
        setTimeout(() => {
            setloadingaccessitems(false);
        }, 1000);
        setAccessItems({
            ...activeroleData,
            AccessItems: compare(activeroleData.AccessItems, Permissions),
        });
    };
    const updateHandler = () => {
        let body = JSON.stringify({ AccessItems: accessItems.AccessItems });
        Services.updaterole("PUT", body, token, accessItems.AdminRoleID)
            .then((res) => {
                console.log(res);
                if (res.Status === 1) {
                    getAdminroles();
                    SuccessSwal("Roles updated successfully", "");
                } else if (res.Status === 0) {
                    warningSwal("Warning", res.Message);
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const DeleteHandler = () => {
        // let body = JSON.stringify({ accessItems: { accessItems } });
        if (window.confirm("Are you sure you want to delete role")) {
            Services.DeleteRole("Delete", null, token, accessItems.AdminRoleID)
                .then((res) => {
                    console.log(res);
                    setRoleName("");
                    getAdminroles();
                    if (res.Status === 1) {
                        toast.success(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (res.Status === 0) {
                        toast.error(`${res.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };
    const checkHandler = (e) => {
        let name = e.currentTarget.getAttribute("name");
        let Type = e.currentTarget.getAttribute("check-type");

        let updatedUserPermission;
        if (Type === "write") {
            if (e.target.checked) {
                updatedUserPermission = accessItems.AccessItems.map((item) => {
                    if (item.name === name) {
                        return {
                            ...item,
                            Permissions: {
                                read: 1,
                                write: 1,
                                edit: item.Permissions.edit,
                            },
                        };
                    }
                    return item;
                });
            } else {
                updatedUserPermission = accessItems.AccessItems.map((item) => {
                    if (item.name === name) {
                        return {
                            ...item,
                            Permissions: {
                                read: item.Permissions.read,
                                edit: item.Permissions.edit,
                                write: 0,
                            },
                        };
                    }
                    return item;
                });
            }
        } else if (Type === "read") {
            if (e.target.checked) {
                updatedUserPermission = accessItems.AccessItems.map((item) => {
                    if (item.name === name) {
                        return {
                            ...item,
                            Permissions: {
                                read: 1,
                                write: item.Permissions.write,
                                edit: item.Permissions.edit,
                            },
                        };
                    }
                    return item;
                });
            } else {
                updatedUserPermission = accessItems.AccessItems.map((item) => {
                    if (item.name === name) {
                        return {
                            ...item,
                            Permissions: {
                                read: 0,
                                write: 0,
                                edit: item.Permissions.edit,
                            },
                        };
                    }
                    return item;
                });
            }
        } else if (Type === "edit") {
            if (e.target.checked) {
                updatedUserPermission = accessItems.AccessItems.map((item) => {
                    if (item.name === name) {
                        return {
                            ...item,
                            Permissions: {
                                read: 1,
                                write: 1,
                                edit: 1,
                            },
                        };
                    }
                    return item;
                });
            } else {
                updatedUserPermission = accessItems.AccessItems.map((item) => {
                    if (item.name === name) {
                        return {
                            ...item,
                            Permissions: {
                                read: item.Permissions.read,
                                write: item.Permissions.write,
                                edit: 0,
                            },
                        };
                    }
                    return item;
                });
            }
        }
        setAccessItems((prev) => ({
            ...prev,
            AccessItems: updatedUserPermission,
        }));
    };
    const ReadonlyAccess = [
        AccessKeys.Dashboard,
        AccessKeys.Calender,
        // AccessKeys["Student Registrations"],
        AccessKeys.Enquiry,
        AccessKeys.Complaints,
        AccessKeys["Contact us"],
        AccessKeys["Demo Requests"],
        AccessKeys.Notifications,
        AccessKeys["TutorAvailability"],
        AccessKeys["Reports"],
    ];
    const WriteNotAllowed = [
        AccessKeys.Dashboard,
        AccessKeys.Calender,
        // AccessKeys["Student Registrations"],
        AccessKeys.Enquiry,
        AccessKeys.Complaints,
        AccessKeys["Contact us"],
        AccessKeys["Demo Requests"],
        AccessKeys.Notifications,
        AccessKeys["TutorAvailability"],
        AccessKeys["Reports"],
        AccessKeys.BulkPayout,
    ];
    const Find_Access_Type = (name) => {
        let Item = ReadonlyAccess.filter((item) => {
            console.log(item, name);
            return item === name;
        });
        if (Item.length > 0) {
            return true;
        }
        return false;
    };
    const Find_Access_Type_write = (name) => {
        let Item = WriteNotAllowed.filter((item) => {
            console.log(item, name);
            return item === name;
        });
        if (Item.length > 0) {
            return true;
        }
        return false;
    };
    return (
        <>
            <Layout Active={"Roles"}>
                <div className={rolesClasses["dashboard-rightside"]}>
                    <div className={rolesClasses.CategoryWrapper}>
                        {/* <h3>Roles</h3> */}

                        {loading ? (
                            <div className={rolesClasses["loader"]}>
                                <MainLoad />
                            </div>
                        ) : (
                            <>
                                <div className={rolesClasses["wrapper"]}>
                                    <header>
                                        <select onChange={activeHandler}>
                                            {adminroles.map((item) => (
                                                <option
                                                    value={item.AdminRoleID}>
                                                    {item.RoleName}
                                                </option>
                                            ))}
                                        </select>
                                        <button
                                            onClick={() => {
                                                Navigate("/AddRole");
                                            }}>
                                            Add Role
                                        </button>
                                    </header>

                                    <div className={rolesClasses["Table"]}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Pages</th>
                                                    <th>Read</th>
                                                    <th>Write</th>
                                                    <th>Edit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {accessItems?.AccessItems?.map(
                                                    (item) => (
                                                        <tr>
                                                            <td>{item.name}</td>
                                                            <td>
                                                                <input
                                                                    check-type={
                                                                        "read"
                                                                    }
                                                                    name={
                                                                        item.name
                                                                    }
                                                                    onChange={
                                                                        checkHandler
                                                                    }
                                                                    type="checkbox"
                                                                    checked={
                                                                        item
                                                                            .Permissions
                                                                            .read ===
                                                                        1
                                                                    }
                                                                />
                                                            </td>
                                                            <td>
                                                                {Find_Access_Type_write(
                                                                    item.name
                                                                ) ? (
                                                                    <span
                                                                        style={{
                                                                            textAlign:
                                                                                "center",
                                                                        }}>
                                                                        -
                                                                    </span>
                                                                ) : (
                                                                    <input
                                                                        name={
                                                                            item.name
                                                                        }
                                                                        check-type={
                                                                            "write"
                                                                        }
                                                                        onChange={
                                                                            checkHandler
                                                                        }
                                                                        type="checkbox"
                                                                        checked={
                                                                            item
                                                                                .Permissions
                                                                                .write ===
                                                                            1
                                                                        }
                                                                    />
                                                                )}
                                                            </td>
                                                            <td>
                                                                {Find_Access_Type(
                                                                    item.name
                                                                ) ? (
                                                                    <span
                                                                        style={{
                                                                            textAlign:
                                                                                "center",
                                                                        }}>
                                                                        -
                                                                    </span>
                                                                ) : (
                                                                    <input
                                                                        name={
                                                                            item.name
                                                                        }
                                                                        check-type={
                                                                            "edit"
                                                                        }
                                                                        onChange={
                                                                            checkHandler
                                                                        }
                                                                        type="checkbox"
                                                                        checked={
                                                                            item
                                                                                .Permissions
                                                                                .edit ===
                                                                            1
                                                                        }
                                                                    />
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div
                                        className={rolesClasses["Action-btns"]}>
                                        <button onClick={updateHandler}>
                                            Update
                                        </button>
                                        <button onClick={DeleteHandler}>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Layout>
        </>
    );
}

export default Warehouse;
