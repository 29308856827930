
import {
 AiOutlineDashboard
} from "react-icons/ai";
import { BsFillPersonFill } from 'react-icons/bs'
import uuid from "react-uuid";
import { GiMasterOfArms } from 'react-icons/gi'
import { RiParentLine } from 'react-icons/ri'
import { FaLaptopCode, FaUserPlus, FaWpforms, FaEnvelope, FaQuestionCircle } from 'react-icons/fa'
import { MdNewspaper, MdOutlineAdminPanelSettings } from 'react-icons/md'
import { TbReportAnalytics } from "react-icons/tb";
import { MdPayments } from "react-icons/md";

import { IoIosNotifications, IoIosCalendar, IoMdList, IoIosFolder, IoIosBook, IoIosTime, IoIosLock } from 'react-icons/io'
import { TiTickOutline } from 'react-icons/ti'
import { RiCustomerService2Line } from 'react-icons/ri'
import { ImBooks } from 'react-icons/im'
import { MdOutlineCancelPresentation } from "react-icons/md"
import { MdOutlineEventAvailable } from "react-icons/md";
import { FcDataConfiguration } from 'react-icons/fc'
export const menuItems = [
 {
  id: uuid(),
  multiple: false,
  name: "Dashboard",
  menuLink: "/Dashboard",
  active: "Dashboard",
  icon: AiOutlineDashboard,
 },
 {
  id: uuid(),
  multiple: false,
  name: "Calender",
  menuLink: "/Calender",
  active: "Calender",
  icon: IoIosCalendar,
 },
 {
  id: uuid(),
  multiple: true,
  name: "People",
  menuLink: "/people",
  active: "People",
  items: [{ name: 'Tutor', menuLink: "/Tutor?tab=2&page=1", active: "Tutor", icon: GiMasterOfArms }, { name: "Parents", menuLink: '/Parent?tab=1&page=1', active: 'Parent', icon: RiParentLine, }, { name: "Students", menuLink: '/Students?page=1', active: 'Students', icon: RiParentLine, }, { name: "Administrators", menuLink: '/Administrators?page=1', active: 'Administrators', icon: RiParentLine, }],
  icon: BsFillPersonFill,
 },

 {
  id: uuid(),
  multiple: false,
  name: "Categories",
  menuLink: "/categories",
  active: "Categories",
  icon: IoIosFolder,

 },
 {
  id: uuid(),
  multiple: false,
  name: "Courses",
  menuLink: "/courses",
  active: "Courses",
  icon: IoIosBook,
 },


 {
  id: uuid(),
  multiple: false,
  name: "Batches",
  menuLink: "/Batches?tab=NEEDAPPROVAL&page=1",
  active: "Batches",
  icon: IoMdList,
 },
 {
  id: uuid(),
  multiple: false,
  name: "Scheduled Classes",
  menuLink: "/scheduledClasses?tab=1&page=1",
  active: "scheduledClasses",
  icon: IoIosTime,
 },

 {
  id: uuid(),
  multiple: false,
  name: "Bulk Cancel Classes",
  menuLink: "/bulk-cancel",
  active: "Bulkcancel",
  icon: MdOutlineCancelPresentation,
 },
 {
  id: uuid(),
  multiple: true,
  name: "Permissions",
  menuLink: "/Permissions",
  active: "Permissions",
  icon: IoIosLock,
  items: [{
   id: uuid(),
   multiple: false,
   name: "Roles",
   menuLink: "/roles",
   active: "Roles",
   icon: MdOutlineAdminPanelSettings,
  },
  {
   id: uuid(),
   multiple: false,
   name: "Approval",
   menuLink: "/Approvals?type=penalty_requests",
   active: "Approvals",
   icon: TiTickOutline,
  },],

 },
 {
  id: uuid(),
  multiple: false,
  name: "Student Registrations",
  menuLink: "/Student-registration",
  active: "Enrolled-list",
  icon: FaUserPlus,
 },

 {
  id: uuid(),
  multiple: true,
  name: "Support",
  menuLink: "/Permissions",
  active: "Permissions",
  icon: RiCustomerService2Line,
  items: [{
   id: uuid(),
   multiple: false,
   name: "Enquiry",
   menuLink: "/enquiryForm",
   active: "EnquiryForm",
   icon: MdNewspaper,

  },

  {
   id: uuid(),
   multiple: false,
   name: "Complaints",
   menuLink: "/Complaint",
   active: "Complaint",
   icon: FaQuestionCircle,
  }, {
   id: uuid(),
   multiple: false,
   name: "Contact Us",
   menuLink: "/contact-us",
   active: "ContactUs",
   icon: FaWpforms,
  },],

 },

 {
  id: uuid(),
  multiple: false,
  name: "Demo Requests",
  menuLink: "/demo",
  active: "Demo Requests",
  icon: FaEnvelope,
 },
 {
  id: uuid(),
  multiple: false,
  name: "Demo Classes",
  menuLink: "/demo-classes",
  active: "Demo Classes",
  icon: FaEnvelope,
 },
 {
  id: uuid(),
  multiple: false,
  name: "Reports",
  menuLink: "/reports",
  active: "Reports",
  icon: TbReportAnalytics,
 },
 {
  id: uuid(),
  multiple: false,
  name: "Tutor Availability",
  menuLink: "/Tutor-availability",
  active: "availability",
  icon: MdOutlineEventAvailable,
 },

 {
  id: uuid(),
  multiple: false,
  name: "Notifications",
  menuLink: "/Notifications",
  active: "Notifications",
  icon: IoIosNotifications,
 },
 {
  id: uuid(),
  multiple: false,
  name: "Resources",
  menuLink: "/Resource",
  active: "Resources",
  icon: ImBooks,
 },
 {
  id: uuid(),
  multiple: false,
  name: "Bulk Payouts",
  menuLink: "/bulk-payouts",
  active: "bulk-payouts",
  icon: MdPayments,
 },
 {
  id: uuid(),
  multiple: false,
  name: "Configuration",
  menuLink: "/config",
  active: "Configuration",
  icon: FcDataConfiguration,
 },


];

