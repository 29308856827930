

import Dashboard from "../pages/Dashboard/dasboard";
import Tutor from "../pages/People/TutorManagement/Tutor/Tutor"

import Students from "../pages/People/StudentManagement/Students/students";
import StudentDetails from "../pages/People/StudentManagement/StudentDetails/details"
import Administrators from '../pages/People/AdminManagement/admin'
import AdminDetails from "../pages/People/AdminManagement/AdminDetails/details"
import AdminAdd from "../pages/People/AdminManagement/AdminAdd/index"
import AddTutor from "../pages/People/TutorManagement/AddTutor"
import TurtorDetails from "../pages/People/TutorManagement/TutorDetails"
import StudentAdd from "../pages/People/StudentManagement/StudentAdd"
import Parent from "../pages/People/Parent/Parent"
import ParentDetails from "../pages/People/Parent/parentDetails/index"
import ParentAdd from "../pages/People/Parent/AddParent"
import Categories from "pages/Categories/index"
import AddCategory from "../pages/Categories/addCategory"
import Courses from "../pages/course/course/index"
import AddCourse from "../pages/course/addcourse"
import Enrolled from "../pages/enrolled/enrolled/enrolled"
import Demo from "../pages/Demo/demo/demo"
import CreateBatch from "../pages/Batches/schedulebatch"
import Batch from "../pages/Batches/Batches"
import Details from '../pages/Batches/details/details'
import ScheduleClasses from '../pages/scheduledClasses/classes'
import ClassesDetails from '../pages/scheduledClasses/details/details'
import ScheduleClassAdd from "../pages/Batches/createClasses"
import Enquiry from "../pages/EnquiryForm/enquiry"
import ContactUs from "../pages/ContactUS"
import Complaint from "../pages/Complaints"
import Roles from "../pages/Roles/roles"
import AddRole from "../pages/Roles/addRole"
import Notifications from "../pages/Notifications/notifications"
import Approval from "../pages/Approval/index"
import Calender from "../pages/Calender"
import Editenroll from '../pages/enrolled/enrolled/edit'
import StudentRegistrationDetails from "../pages/enrolled/details/details"
import LessonPlan from "../pages/course/LessonPlan/lessonPlan"
import AddLessonPlan from "../pages/course/LessonPlan/addLessonPlan"
import Login from "../pages/Login/index"
import Resources from "../pages/Resource/index"
import AddupdateResource from "../pages/Resource/Add"
import TutorAvailability from '../pages/TutorAvailability/Availability'
import BulkCancel from "../pages/BulkCancel/cancel"
import Config from '../pages/Config/config/index'
import AddConfig from '../pages/Config/addconfig'
import AddRegistration from '../pages/enrolled/enrolled/Add'
import Reports from 'pages/Reports'
import BulkPayouts from 'pages/BulkPayouts/DraftPayouts'
import PayoutDetails from 'pages/BulkPayouts/PayoutDetails'
import CreateBulk from 'pages/BulkPayouts/payouts'
import AddDemoRequest from 'pages/DemoClasses/addDemo/index'
import AddDemoStudent from 'pages/Demo/addDemoStudent'
import DemoClasses from 'pages/DemoClasses/demo'
export const AccessKeys = {
 Dashboard: 'Dashboard',
 Administrators: 'Administrators',
 Tutor: 'Tutor',
 Students: 'Students',
 Parents: 'Parents',
 Categories: 'Categories',
 Courses: 'Courses',
 "Student Registrations": "Student Registrations",
 "Demo Requests": "Demo Requests",
 'Demo Classes': "Demo Classes",
 Batches: "Batches",
 "Scheduled Classes": "Scheduled Classes",
 Enquiry: "Enquiry",
 'Contact us': 'Contact Us',
 Complaints: 'Complaints',
 Roles: 'Roles',
 Notifications: 'Notifications',
 Approval: "Approval",
 Calender: 'Calender',
 Resources: 'Resources',
 Login: 'login',
 TutorAvailability: 'Tutor Availability',
 bulkCancel: 'Bulk Cancel Classes',
 Config: 'Configuration',
 Reports: 'Reports',
 BulkPayout: "Bulk Payouts"


}


export const PageRoutes = [


 { path: '/', element: <Login />, AccessKey: AccessKeys.Login, Type: 'read' },
 { path: '/Dashboard', element: <Dashboard />, AccessKey: AccessKeys.Dashboard, Type: 'read' },

 { path: '/Administrators', element: <Administrators />, AccessKey: AccessKeys.Administrators, Type: 'read' },

 { path: '/administrator/add', element: <AdminAdd />, AccessKey: AccessKeys.Administrators, Type: 'write' },

 { path: '/Update-admin', element: <AdminAdd />, AccessKey: AccessKeys.Administrators, Type: 'write' },

 { path: '/Administrators/details', element: <AdminDetails />, AccessKey: AccessKeys.Administrators, Type: 'read' },

 { path: '/Tutor', element: <Tutor />, AccessKey: AccessKeys.Tutor, Type: 'read' },

 { path: '/Tutor/Add', element: <AddTutor />, AccessKey: AccessKeys.Tutor, Type: 'write' },

 { path: '/Tutor/update', element: <AddTutor />, AccessKey: AccessKeys.Tutor, Type: 'write' },

 { path: '/Tutor/Details', element: <TurtorDetails />, AccessKey: AccessKeys.Tutor, Type: 'read' },

 { path: '/Students', element: <Students />, AccessKey: AccessKeys.Students, Type: 'read' },
 { path: '/student/add', element: <StudentAdd />, AccessKey: AccessKeys.Students, Type: 'write' },

 { path: '/student/update', element: <StudentAdd />, AccessKey: AccessKeys.Students, Type: 'write' },

 { path: '/Students/details', element: <StudentDetails />, AccessKey: AccessKeys.Students, Type: 'read' },

 { path: '/Parent', element: <Parent />, AccessKey: AccessKeys.Parents, Type: 'read' },

 { path: '/Parent/Add', element: <ParentAdd />, AccessKey: AccessKeys.Parents, Type: 'write' },

 { path: '/Parent/Update', element: <ParentAdd />, AccessKey: AccessKeys.Parents, Type: 'write' },

 { path: '/parent/Details', element: <ParentDetails />, AccessKey: AccessKeys.Parents, Type: 'read' },

 { path: '/categories', element: <Categories />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/categories/:category', element: <AddCategory />, AccessKey: AccessKeys.Categories, Type: 'write' },
 { path: '/courses', element: <Courses />, AccessKey: AccessKeys.Courses, Type: 'read' },
 { path: '/courses/Lessonplan', element: <LessonPlan />, AccessKey: AccessKeys.Courses, Type: 'read' },

 { path: '/courses/Lessonplan/Add', element: <AddLessonPlan />, AccessKey: AccessKeys.Courses, Type: 'write' },
 { path: '/courses/:addupdate', element: <AddCourse />, AccessKey: AccessKeys.Courses, Type: 'write' },

 { path: '/Student-registration', element: <Enrolled />, AccessKey: AccessKeys['Student Registrations'], Type: 'read' },

 { path: '/Student-registration/details', element: <StudentRegistrationDetails />, AccessKey: AccessKeys['Student Registrations'], Type: 'read' },
 { path: '/Student-reigistration/Add', element: <AddRegistration />, AccessKey: AccessKeys['Student Registrations'], Type: 'write' },

 { path: '/edit-enroll/:id', element: <Editenroll />, AccessKey: AccessKeys['Student Registrations'], Type: 'edit' },

 { path: '/demo', element: <Demo />, AccessKey: AccessKeys['Demo Requests'], Type: 'read' },


 { path: '/add-demo-student', element: <AddDemoStudent />, AccessKey: AccessKeys['Demo Requests'], Type: 'write' },
 { path: '/edit-demo-class/:id', element: <AddDemoRequest />, AccessKey: AccessKeys['Demo Requests'], Type: 'edit' },
 { path: '/demo-classes', element: <DemoClasses />, AccessKey: AccessKeys['Demo Classes'], Type: 'read' },

 { path: '/add-demo-class', element: <AddDemoRequest />, AccessKey: AccessKeys['Demo Classes'], Type: 'write' },
 { path: '/Batches', element: <Batch />, AccessKey: AccessKeys.Batches, Type: 'read' },

 { path: '/Batches/add', element: <CreateBatch />, AccessKey: AccessKeys.Batches, Type: 'write' },

 { path: '/Batches/update', element: <CreateBatch />, AccessKey: AccessKeys.Batches, Type: 'write' },

 { path: '/Batches/Details', element: <Details />, AccessKey: AccessKeys.Batches, Type: 'read' },

 { path: '/scheduledClasses', element: <ScheduleClasses />, AccessKey: AccessKeys['Scheduled Classes'], Type: 'read' },

 { path: '/scheduledClasses/Details', element: <ClassesDetails />, AccessKey: AccessKeys['Scheduled Classes'], Type: 'read' },

 { path: '/scheduleClass/add', element: < ScheduleClassAdd />, AccessKey: AccessKeys["Scheduled Classes"], Type: 'write' },

 { path: '/enquiryForm', element: < Enquiry />, AccessKey: AccessKeys.Enquiry, Type: 'read' },

 { path: '/contact-us', element: < ContactUs />, AccessKey: AccessKeys['Contact us'], Type: 'read' },

 { path: '/complaint', element: < Complaint />, AccessKey: AccessKeys.Complaints, Type: 'read' },

 { path: '/roles', element: < Roles />, AccessKey: AccessKeys.Roles, Type: 'read' },

 { path: '/AddRole', element: < AddRole />, AccessKey: AccessKeys.Roles, Type: 'write' },

 { path: '/editRole', element: < AddRole />, AccessKey: AccessKeys.Roles, Type: 'write' },

 { path: '/Notifications', element: < Notifications />, AccessKey: AccessKeys.Notifications, Type: 'read' },

 { path: '/Approvals', element: < Approval />, AccessKey: AccessKeys.Approval, Type: 'read' },

 { path: '/Calender', element: < Calender />, AccessKey: AccessKeys.Calender, Type: 'read' },

 { path: '/Resource', element: < Resources />, AccessKey: AccessKeys.Resources, Type: 'read' },

 { path: '/Resource/:Type', element: < AddupdateResource />, AccessKey: AccessKeys.Resources, Type: 'write' },
 { path: '/Tutor-availability', element: < TutorAvailability />, AccessKey: AccessKeys.TutorAvailability, Type: 'read' },
 { path: '/bulk-cancel', element: < BulkCancel />, AccessKey: AccessKeys.bulkCancel, Type: 'write' },
 { path: '/config', element: < Config />, AccessKey: AccessKeys.Config, Type: 'read' },
 { path: '/config/add', element: < AddConfig />, AccessKey: AccessKeys.Config, Type: 'write' },
 { path: '/config/edit', element: < AddConfig />, AccessKey: AccessKeys.Config, Type: 'edit' },
 { path: '/Reports', element: < Reports />, AccessKey: AccessKeys.Reports, Type: 'read' },
 { path: '/bulk-payouts', element: <BulkPayouts />, AccessKey: AccessKeys.BulkPayout, Type: 'read' },
 { path: '/payout-details', element: <PayoutDetails />, AccessKey: AccessKeys.BulkPayout, Type: 'read' },
 { path: '/CreateBulk', element: <CreateBulk />, AccessKey: AccessKeys.BulkPayout, Type: 'read' },


]